if (pageDetails.fileName === 'gallery.html') {
    window.addEventListener('load', () => {
        const thumbnails = Array.from(document.getElementById('thumbnail-grid').getElementsByTagName('img'));
        const expandedImageOverlay = document.getElementById('expanded-image-overlay');
        const expandedImage = expandedImageOverlay.querySelector('img');
        const description = document.getElementById('description');
        
        // Set the iniital image and description to the first in the set
        let imageSource = thumbnails[0].getAttribute('data-full');
        let imageDescription = thumbnails[0].getAttribute('data-desc');
        expandedImage.setAttribute('src', imageSource);
        description.innerText = imageDescription;

        // Keep track of the current index
        let currentImageIndex;

        function setOverlayContent(image) {
            imageSource = image.getAttribute('data-full');
            imageDescription = image.getAttribute('data-desc');
            expandedImage.setAttribute('src', imageSource);
            description.innerText = imageDescription;
        }

        thumbnails.forEach((thumbnail, index) => {
            thumbnail.addEventListener('click', () => {
                thumbnail.classList.add('active');
                currentImageIndex = index;

                setOverlayContent(thumbnail);

                expandedImageOverlay.style.opacity = 1;
                expandedImageOverlay.style.pointerEvents = 'auto';

            });
        });


        // Close the overlay when you click inside the image, outside its left and right borders,
        // or the close button.
        const overlayBackground = document.getElementById('background-filter');
        const closeButton = document.getElementById('close');

        function hideOverlay() {
            expandedImageOverlay.style.opacity = 0;
            expandedImageOverlay.style.pointerEvents = 'none';
            thumbnails[currentImageIndex].classList.remove('active');
        }

        expandedImage.addEventListener('click', hideOverlay);
        overlayBackground.addEventListener('click', hideOverlay);
        closeButton.addEventListener('click', hideOverlay);


        // Switch between images in the gallery via the previous and next buttons without
        // closing the overlay
        const previousButton = document.getElementById('prev');
        const nextButton = document.getElementById('next');

        previousButton.addEventListener('click', () => {
            thumbnails[currentImageIndex].classList.remove('active');
            if (currentImageIndex === 0) {
                currentImageIndex = thumbnails.length - 1;
            } else {
                currentImageIndex--;
            }

            setOverlayContent(thumbnails[currentImageIndex]);

            thumbnails[currentImageIndex].classList.add('active');

        });

        nextButton.addEventListener('click', () => {
            thumbnails[currentImageIndex].classList.remove('active');

            if (currentImageIndex === thumbnails.length - 1) {
                currentImageIndex = 0;
            } else {
                currentImageIndex++;
            }

            setOverlayContent(thumbnails[currentImageIndex]);

            thumbnails[currentImageIndex].classList.add('active');
        });

    });
}