window.addEventListener('load', () => {
    const topOverlay = document.getElementById('top-overlay');
    const overlayContainer = document.getElementById('overlay-container');

    const logoWrapper = document.getElementById('logo-wrapper');
    const logo = document.getElementById('logo');

    logo.addEventListener('click', () => {
        window.location.href = '/';
    });

    const navMenu = document.getElementById('nav-menu');
    const menuIcon = document.getElementById('menu-icon');

    const siteLinksContainer = document.getElementById('site-links');
    const siteLinks = Array.from(siteLinksContainer.getElementsByTagName('a'));

    let scrollPoint = 100;
    let overlayToggled = window.pageYOffset >= scrollPoint;

    const logoBP1 = 300; // for width
    const logoBP2 = 745; // for width
    const landscapeBP = 480; // for height, pairs with logoBP2

    function toggleBar() {
        // anime({
        //     targets: topOverlay,
        //     height: overlayToggled
        //         ? 70 +'px'
        //         : (window.innerWidth > logoBP2)
        //             ? 175 +'px'
        //             : (175 / 2) +'px',
        //     duration: 600,
        //     easing: 'easeOutQuad'
        // });

        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled (at any viewport width),
            // set the top bar height to 70px
            topOverlay.style.height = 70 + 'px';

        } else {
            if (window.innerWidth > logoBP2 && window.innerHeight > landscapeBP) {
                // If the viewport is wide enough for the bigger "large form"
                // logo, set the top bar height to 175px (default)
                topOverlay.style.height = 175 + 'px';

            } else {
                // else set the height to half that height
                // (logo will also become smaller)
                topOverlay.style.height = (175 / 2) + 'px';
            }
        }
    }

    const logoImage = logo.firstElementChild;
    function toggleLogo() {
        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled OR the screen width is <= 20px wide,
            // set the logo to its smallest form (without the background) and
            // make it appear on the left of the screen.
            logoImage.setAttribute('src', logoImage.getAttribute('data-small'));
            logo.classList.replace('large', 'small');
            logoWrapper.style.width = '70px';

            logo.style.marginLeft = '0px';
            logo.style.width = '';
            logo.style.height = '';
            logo.style.padding = '';

        } else {
            // If the overlay is untoggled (scroll position is at the very top)
            // Reset the logo to its default styles based on viewport width
            logoImage.setAttribute('src', logoImage.getAttribute('data-large'));
            logo.classList.replace('small', 'large');
            logo.style.marginLeft = '';

            if (window.innerWidth > logoBP2 && window.innerHeight > landscapeBP) {
                logoWrapper.style.width = (overlayContainer.clientWidth / 2) + 'px';
                logo.style.width = '350px';
                logo.style.height = '350px';
                logo.style.padding = '0px';
            } else {
                logoWrapper.style.width = '175px';
                logo.style.width = '175px';
                logo.style.height = '175px';
                logo.style.padding = '10px';
            }
        }
    }

    const iconBP = 570;

    function toggleMenu() {
        if (overlayToggled || window.innerWidth <= logoBP1) {
            // If the overlay is toggled OR the screen width is <= 20px wide,
            // set the nav menu width to the overall width minus the logo width
            // container width - left & right padding - small logo width
            let menuWidth = overlayContainer.clientWidth - 20 - 75;
            navMenu.style.width = menuWidth + 'px';

            siteLinksContainer.style.transform = `translateX(${(menuWidth / 2) - (siteLinksContainer.clientWidth / 2)}px)`;
            menuIcon.style.transform = `translateX(${menuWidth / 2 - 20}px)`;

            if (window.innerWidth <= iconBP) {
                // Adjust the positioning of the siteLinksContainer if the
                // viewport width is <= the menu icon breakpoint.
                siteLinksContainer.style.top = 70 + 'px';
            }

        } else {
            // If the overlay is untoggled (scroll position is at the very top)
            // Reset the menu styles to their default values
            siteLinksContainer.style.transform = `translateX(0px)`;
            menuIcon.style.transform = `translateX(0px)`;

            if (window.innerWidth > logoBP2 && window.innerHeight > landscapeBP) {
                // nav menu block is 50% of its container wide
                navMenu.style.width = (overlayContainer.clientWidth / 2) + 'px';
            } else {
                // The overall container width minus the resized large logo width
                // container width - left & right padding - large logo width (at <= 745px wide)
                navMenu.style.width = overlayContainer.clientWidth - 20 - 175 + 'px';
            }

            if (window.innerWidth <= iconBP) {
                // Adjust the positioning of the siteLinksContainer if the
                // viewport width is <= the menu icon breakpoint.
                siteLinksContainer.style.top = '';
            }
        }
    }

    function toggleOverlay() {
        toggleBar();
        toggleLogo();
        toggleMenu();
    }

    // Apply transformations if the page has been reloaded
    if (overlayToggled) {
        toggleOverlay();
    }

    // -------------------------------------------- Menu Icon Button --------------------------------------------
    let menuOpen = false;
    menuIcon.addEventListener('click', () => {
        menuIcon.classList.toggle('open');

        // Get the total scroll height of the site links
        let siteLinksHeight = siteLinks.reduce((accum, current) => {
            return accum + 20 + current.scrollHeight;
        }, 0);

        if (!menuOpen) {
            // If tapped/clicked set the menu to opened and reveal the site links
            siteLinksContainer.style.height = siteLinksHeight + 'px';
            menuOpen = true;
        } else {
            // hide the site links if already opened
            siteLinksContainer.style.height = 0 + 'px';
            menuOpen = false;
        }

    });

    // -------------------------------------------- Other Event Handlers --------------------------------------------
    window.addEventListener('scroll', () => {
        // Toggle the overlay when scrolling past the scroll point
        if (!overlayToggled && window.pageYOffset >= scrollPoint) {
            overlayToggled = true;
            toggleOverlay();
        } else if (overlayToggled && window.pageYOffset < scrollPoint) {
            overlayToggled = false;
            toggleOverlay();
        }
    });

    // toggle the overlay (applies the correct transformation) if the page is resized
    window.addEventListener('resize', toggleOverlay);
});