if (pageDetails.fileName === 'about.html') {
    window.addEventListener('load', () => {
        // This will be very much like the services inner page navigation elements with a few adjustments.
        // Refer to the 'services-script.js' file for explanations

        // Refer directly to the sections instead of the seperator elements
        const sections = Array.from(document.getElementsByClassName('anchor'));
        
        // At the moment the only inner page links are the 3 buttons at the top of the page and the buttons in the bottom overlay
        const innerPageLinks = Array.from(document.getElementsByClassName('innerPageNavigation'));
        innerPageLinks.forEach((link) => {
            let sectionIndex = link.getAttribute('data-section');
            link.addEventListener('click', (event) => {
                scrollToElement(sectionIndex >= 0 ? sections[sectionIndex] : document.body, 90); 
                event.preventDefault();
            });
        });

        const specialtyButtons = Array.from(document.getElementsByClassName('specialtyButton'));
        const specialtyBlurbs = Array.from(document.getElementsByClassName('specialtyBlurb'));
        const itemLists = Array.from(document.getElementsByTagName('ul'));


        specialtyButtons.forEach((button, index) => {
            let opened;
            button.addEventListener('click', () => {
                if (!opened) {
                    specialtyBlurbs[index].style.height = '100%';
                    itemLists[index].style.height = '100%';
                    opened = true;
                } else {
                    specialtyBlurbs[index].style.height = '0px';
                    itemLists[index].style.height = '0px';
                    opened = false;
                }
                
            });
        });

    });
}