// If on the home page (index.html -> route is '')
if (pageDetails.fileName === '' || pageDetails.fileName === 'index.html') {
    window.addEventListener('load', () => {
        const banner = document.getElementById('home-banner');

        const imageOverlay = document.getElementById('banner-image-overlay');
        const imageOverlayText = imageOverlay.querySelector('h1');

        let imageHeight = window.innerHeight - (window.innerWidth > 570 ? 120 : 110);
        function adjustBanner() {
            // Different mobile browsers render initial viewport height differently
            // due to how they choose to deal with the address/menu bar.

            // Recalculate the image height and inline the style to provide a consistent experience.
            // Video area padding is reduced on a smaller width. (20px -> 10px), service description
            // height remains the same (100px, except in the case where device is <= 270px wide).
            imageHeight = window.innerHeight - (window.innerWidth > 570 ? 120 : 110);
            banner.style.height = imageHeight +'px';

            if (imageOverlayText.scrollHeight > (imageHeight * 0.20)) {
                imageOverlay.style.height = imageOverlayText.scrollHeight +'px';
            } else {
                imageOverlay.style.height = (imageHeight * 0.20) + 'px';
            }
        }

        // Adjust the banner on page load
        adjustBanner();

        /**
         * Toggles an overlay that displays the company tag line over the banner image.
         * Takes a boolean value dictating whether it will reveal or hide the overlay,
         * this way the same animation function can be applied to different mouse events.
         * 
         * @param {boolean} reveal whether to reveal or hide the overlay with this animation.
         */
        function toggleImageOverlay(reveal) {
            anime({
                targets: imageOverlay,
                opacity: reveal ? 1 : 0, // Transition the overlay to opaque or transparent
                height: reveal // Transition the overlay by expanding the height or dropping to 0px
                    // If the text is larger than 20% of the banner image
                    ? imageOverlayText.scrollHeight > (imageHeight * 0.20)
                        // Set the overlay size to the scroll height (required size to fit text)
                        ? imageOverlayText.scrollHeight +'px'
                        // Otherwise set it to 20% of the banner image
                        : (imageHeight * 0.20) +'px'
                    : '0px',
                duration: 600,
                easing: 'easeOutQuad'
            });
        }

        banner.addEventListener('mouseenter', () => {
            toggleImageOverlay(true);
        });

        banner.addEventListener('mouseleave', () => {
            toggleImageOverlay(false);
        });

        const initHeight = window.innerHeight;
        window.addEventListener('resize', () => {
            // Adjust the banner size if the screen is resized smaller than its
            // initial height, but not larger. This prevents the "jumping" affect in mobile
            // Firefox and Safari when you start scrolling and the url bar disappears, while
            // also keeping the view nice as you resize the screen on desktop.
            if(window.innerHeight < initHeight) adjustBanner(); 
        });

        window.addEventListener('orientationchange', () => {
            // On some mobile devices event fired before the viewport actually changes,
            // so add a small delay of 200ms.
            setTimeout(adjustBanner, 200);
        });
    });
}